import React, { useState, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useRecoilState } from "recoil";
import {
	setPasswordModal as setPasswordModalAtom,
	disableUserModal as disableUserModalAtom,
	deleteUserModal as deleteUserModalAtom,
	currentPoolName as currentPoolNameAtom,
	currentPool as currentPoolAtom,
	addUserModal as addUserModalAtom,
	resetPasswordModal as resetPasswordModalAtom,
	selectedUser as selectedUserAtom,
	editUserModal as editUserModalAtom,
	selectedDepartmentEdit as selectedDepartmentEditAtom,
	editBrandModal as editBrandModalAtom,
} from "./Atoms.js";
import Button from "@material-ui/core/Button";

const UserList = ({ users, id, name }) => {
	const [addUser, setAddUser] = useRecoilState(addUserModalAtom);
	const [disableUserModal, setDisableUserModal] =
		useRecoilState(disableUserModalAtom);
	const [deleteUserModal, setDeleteUserModal] =
		useRecoilState(deleteUserModalAtom);
	const [currentPoolName, setCurrentPoolName] =
		useRecoilState(currentPoolNameAtom);
	const [resetPasswordModalOpen, setResetPasswordModalOpen] = useRecoilState(
		resetPasswordModalAtom
	);
	const [currentPool, setCurrentPool] = useRecoilState(currentPoolAtom);
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const [selectedRow, setSelectedRow] = useState(null);
	const [setPasswordModalOpen, setSetPasswordModalOpen] =
		useRecoilState(setPasswordModalAtom);
	const [editUserModal, setEditUserModal] = useRecoilState(editUserModalAtom);
	const [selectedDepartmentEdit, setSelectedDepartmentEdit] = useRecoilState(
		selectedDepartmentEditAtom
	);
	const [editBrandModal, setEditBrandModal] =
		useRecoilState(editBrandModalAtom);
	const [list, setList] = useState([]);

	const handleAddUserOpen = () => {
		setAddUser(true);
	};

	const handleResetPasswordOpen = () => {
		setResetPasswordModalOpen(true);
	};

	function handleEditDepartment(rowData) {
		setSelectedDepartmentEdit(rowData.email);
		setEditUserModal(true);
	}

	function handleEditBrand(rowData) {
		setSelectedUser(rowData.email);
		setEditBrandModal(true);
	}

	useEffect(() => {
		console.log(users);
		try {
			if (users.length > 0) {
				let l = users?.map((user) => {
					return {
						email: user.Attributes[0]?.Value,
						created: user.UserCreateDate,
						last_modified: user.UserLastModifiedDate,
						status: user.UserStatus,
					};
				});
				setList(l);
			}
		} catch (e) {
			console.log(e);
		}
	}, [users]);

	const style = {
		textAlign: "left",
		fontFamily: '"Montserrat", sans-serif',
	};

	const [state, setState] = useState({
		columns: [
			{
				title: "Email",
				field: "email",
				cellStyle: style,
			},
			// {
			// 	title: "Created",
			// 	field: "created",
			// 	cellStyle: {
			// 		width: "12vw",
			// 		textAlign: "left",
			// 	},
			// 	headerStyle: {
			// 		textAlign: "center",
			// 	},
			// },
			// {
			// 	title: "Last Modified",
			// 	field: "last_modified",
			// 	cellStyle: style,
			// },
			{ title: "Status", field: "status", cellStyle: { width: "50vw" } },
		],
	});

	return (
		<div>
			<div>
				<MaterialTable
					title={`Users (${list.length})`}
					columns={state.columns}
					data={list}
					// style={tableStyle}
					actions={[
						{
							icon: "lock",
							tooltip: "Set Password",
							onClick: (event, rowData) => {
								setCurrentPoolName(name);
								setCurrentPool(id);
								setSelectedUser(rowData.email);
								setSelectedRow(rowData.tableData.id);
								setSetPasswordModalOpen(true);
							},
						},
						(rowData) => ({
							icon: "delete",
							tooltip: "Delete User",
							onClick: (event, rowData) => {
								setDeleteUserModal(true);
								setCurrentPoolName(name);
								setCurrentPool(id);
								setSelectedUser(rowData.email);
								setSelectedRow(rowData.tableData.id);
							},
						}),
						(rowData) => ({
							icon: "block",
							tooltip: "Disable User",
							onClick: (event, rowData) => {
								setDisableUserModal(true);
								setCurrentPoolName(name);
								setCurrentPool(id);
								setSelectedUser(rowData.email);
								setSelectedRow(rowData.tableData.id);
							},
						}),
						{
							icon: "add",
							tooltip: "Add User",
							isFreeAction: true,
							onClick: (event) => {
								setAddUser(true);
								setCurrentPoolName(name);
								setCurrentPool(id);
							},
						},
					]}
					onRowClick={(evt, selectedRow) => {
						setCurrentPoolName(name);
						setCurrentPool(id);
						console.log(selectedRow.email);
						setSelectedUser(selectedRow.email);
						setSelectedRow(selectedRow.tableData.id);
					}}
					// components={{
					// 	Toolbar: (props) => (
					// 		<div>
					// 			<MTableToolbar
					// 				style={{ backgroundColor: "#efb758" }}
					// 				{...props}
					// 			/>
					// 		</div>
					// 	),
					// }}
					options={{
						// rowStyle: rowData => ({
						// 	backgroundColor: selected === rowData.tableData.id ? "#EEE" : "#FFF"
						// }),
						// toolbar: false,
						// rowStyle: (rowData) => ({
						// 	backgroundColor:
						// 		selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
						// }),
						search: true,
						filtering: false,
						paging: false,
						actionsColumnIndex: 8,
						headerStyle: {
							textAlign: "left",
						},
					}}
				/>
			</div>
		</div>
	);
};

export default UserList;
